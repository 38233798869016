import React, { useCallback, useMemo, useState } from 'react'

import Box from '@mui/material/Box/Box'
import Paper from '@mui/material/Paper/Paper'
import Typography from '@mui/material/Typography'
import { Gauge, gaugeClasses } from '@mui/x-charts'
import { LineChart, LineChartSlotProps } from '@mui/x-charts/LineChart'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useGetDashboardStatsQuery, useGetWorkloadStatsQuery } from 'services/dashboard/api'

import {
  AutocompleteOption,
  CommonSelectAutocomplete,
} from 'components/common/CustomSelectAutocomplete/CommonSelectAutocomplete'

//import { mockData as chartsData } from './mockData'

const CustomItemTooltipContent = (props: any) => {
  const { axisData, series, dataIndex } = props

  const payloads = series
    ?.map((seria: any) => ({
      id: seria.id,
      color: seria.color,
      label: seria.label,
      value: seria.data[dataIndex],
    }))
    ?.toSorted((a: any, b: any) => b.value - a.value)

  return (
    <Paper sx={{ padding: 3, backgroundColor: series.color }}>
      <Typography sx={{ mb: 2 }}>
        {axisData?.x?.value && formatToLocaleTimezone(axisData.x.value)}
      </Typography>
      {payloads?.map(
        (payload: any) =>
          !!(payload.value || payload.value === 0) && (
            <Box key={payload.id}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Box sx={{ bgcolor: payload.color, height: '10px', width: '10px' }} />
                <Typography>{payload.label} - </Typography>
                <Typography>{payload.value}</Typography>
              </Box>
            </Box>
          )
      )}
    </Paper>
  )
}

const chartsSlotProps: LineChartSlotProps = {
  legend: {
    hidden: true,
    direction: 'row',
    position: {
      vertical: 'top',
      horizontal: 'left',
    },
    itemMarkWidth: 6,
    itemMarkHeight: 6,
    markGap: 5,
    itemGap: 10,
    labelStyle: {
      fontSize: 10,
    },
  },
}

export const ChartsTab = () => {
  const [ticketSystems, setTicketSystems] = useState<AutocompleteOption>()

  const { data: chartsData } = useGetDashboardStatsQuery(ticketSystems?.value, {
    pollingInterval: 5 * 1000,
    refetchOnMountOrArgChange: true,
  })

  const charts = useMemo(
    () =>
      chartsData?.charts?.map((chart) => ({
        title: chart.title,
        series:
          Object.entries(chart.captions || {})?.map(([key, value]) => ({
            label: `${value}`,
            showMark: false,
            connectNulls: true, //@ts-ignore
            data: chart?.data?.map((val) => val?.items?.[key]) || [],
          })) || [],
        xAxis: {
          data: chart?.data?.map((v) => v.statTime) || [],
        },
      })) || [],
    [chartsData]
  )

  const [chartsLinesSelected, setChartsLinesSelected] = useState(
    charts.map((chart) => ({ chartName: chart.title, selectedLine: '' }))
  )

  const handleChangeTicketSystems = useCallback(
    (value: AutocompleteOption | AutocompleteOption[]) => {
      setTicketSystems(!Array.isArray(value) ? value : { name: '', value: '' })
    },
    []
  )

  const handleChartLineSelect = (chartName: string, lineName: string) => {
    setChartsLinesSelected((prev) =>
      prev.length
        ? prev.map((chart) =>
            chart.chartName === chartName
              ? { chartName, selectedLine: chart.selectedLine !== lineName ? lineName : '' }
              : chart
          )
        : charts.map((chart) =>
            chartName === chart.title
              ? { chartName: chart.title, selectedLine: lineName }
              : { chartName: chart.title, selectedLine: '' }
          )
    )
  }

  const { data: workloadStats } = useGetWorkloadStatsQuery(undefined, {
    pollingInterval: 1 * 1000 * 60,
  })

  const formattedWorkloadStats: any = workloadStats
    ? Object.entries(workloadStats).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: Object.entries(value).reduce(
            (prev, [key, value]) => ({
              ...prev,
              [key]: {
                //@ts-ignore
                value: value.includes('%') ? +value.split('%')[0] : +value.split(' ')[0],
                //@ts-ignore
                units: value.includes('%') ? '%' : value.split(' ')[1],
              },
            }),
            {}
          ),
        }),
        // Object.entries(value).map(([key, value]) => '')
        {}
      )
    : null

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        mt: 1,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 200px)',
        gap: 2,
      }}
    >
      <CommonSelectAutocomplete
        name="accountId"
        label="Ticket system name"
        value={ticketSystems || { name: '', value: '' }}
        onChange={handleChangeTicketSystems}
        url="/api/events/ticket-systems/distinct"
        column="name"
        columnValue="id"
        transformOption={({ name, value }) => ({
          name,
          value,
        })}
      />
      {charts?.map((chart) => (
        <Box key={chart.title} sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ fontSize: 18, width: '100%', textAlign: 'center' }}>
            {chart.title}
          </Typography>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', cursor: 'pointer' }}>
            {chart.series.map((seria) => (
              <Box
                key={seria.label}
                onClick={() => handleChartLineSelect(chart.title, seria.label)}
                sx={{
                  fontSize: '12px',
                  padding: '4px 8px',
                  bgcolor:
                    chartsLinesSelected.find((chartLine) => chartLine.chartName === chart.title)
                      ?.selectedLine === seria.label
                      ? 'primary.main'
                      : '#fff',
                  border: '1px solid #fff',
                  borderColor: 'primary.main',
                  borderRadius: 1,
                }}
              >
                {seria.label}
              </Box>
            ))}
          </Box>

          <LineChart
            xAxis={[
              {
                data: chart.xAxis.data,
                scaleType: 'band',
                valueFormatter: (date) => formatToLocaleTimezone(date),
              },
            ]}
            series={
              chartsLinesSelected.find((chartLine) => chartLine.chartName === chart.title)
                ?.selectedLine.length
                ? chart.series.filter(
                    (ser) =>
                      chartsLinesSelected.find((chartLine) => chartLine.chartName === chart.title)
                        ?.selectedLine === ser.label
                  )
                : chart.series
            }
            height={300}
            slotProps={chartsSlotProps}
            slots={{ axisContent: CustomItemTooltipContent }}
            tooltip={{
              trigger: 'axis',
            }}
          />
        </Box>
      ))}
      <Typography variant="h6" sx={{ fontSize: 18, width: '100%', textAlign: 'center' }}>
        Workload
      </Typography>
      {formattedWorkloadStats ? (
        <Box sx={{ display: 'flex', gap: 5, width: '100%', justifyContent: 'center' }}>
          <Box sx={{ border: '1px solid rgba(0,0,9,0.12)', p: 3, borderRadius: 1 }}>
            <Typography sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}>CPU</Typography>

            <Gauge
              width={200}
              height={200}
              value={formattedWorkloadStats.ramAndCpu.cpuPercent.value}
              text={({ value }) => `${value}${formattedWorkloadStats.ramAndCpu.ramPercent.units}`}
              startAngle={-90}
              endAngle={90}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 16,
                  transform: 'translate(0px, 20px)',
                },
              }}
            />
          </Box>

          <Box sx={{ border: '1px solid rgba(0,0,9,0.12)', p: 3, borderRadius: 1 }}>
            <Typography sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}>HDD</Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', position: 'relative' }}>
              <Gauge
                width={200}
                height={200}
                value={formattedWorkloadStats.hdd.used.value}
                valueMax={formattedWorkloadStats.hdd.total.value}
                text={({ value, valueMax }) =>
                  `${value} ${formattedWorkloadStats.hdd.used.units}/ ${valueMax} ${formattedWorkloadStats.hdd.total.units}`
                }
                startAngle={-90}
                endAngle={90}
                sx={{
                  [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 16,
                    transform: 'translate(0px, 20px)',
                  },
                }}
              />
              <Box>
                <Typography sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}>
                  PG Weight:{' '}
                  {`${formattedWorkloadStats.hdd.pgWeight.value} ${formattedWorkloadStats.hdd.pgWeight.units}`}
                </Typography>
                <Typography sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}>
                  CH Weight:{' '}
                  {`${formattedWorkloadStats.hdd.chWeight.value} ${formattedWorkloadStats.hdd.chWeight.units}`}
                </Typography>
                <Typography sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}>
                  Logs Weight:{' '}
                  {`${formattedWorkloadStats.hdd.logsWeight.value} ${formattedWorkloadStats.hdd.logsWeight.units}`}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ border: '1px solid rgba(0,0,9,0.12)', p: 3, borderRadius: 1 }}>
            <Typography sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}>RAM</Typography>

            <Gauge
              width={200}
              height={200}
              value={formattedWorkloadStats.ramAndCpu.ramUsed.value}
              valueMax={formattedWorkloadStats.ramAndCpu.ramTotal.value}
              text={({ value, valueMax }) =>
                `${value} ${formattedWorkloadStats.ramAndCpu.ramUsed.units}(${formattedWorkloadStats.ramAndCpu.ramPercent.value}${formattedWorkloadStats.ramAndCpu.ramPercent.units})/ ${valueMax} ${formattedWorkloadStats.ramAndCpu.ramTotal.units}`
              }
              startAngle={-90}
              endAngle={90}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 16,
                  transform: 'translate(0px, 20px)',
                },
              }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
