import { QueryTableState } from 'types/TableServerRequest'
import { TableServerResponse } from 'types/TableServerResponse'

import { baseApi } from 'api/api'

import {
  BotLog,
  BotStation,
  CreateReportPayload,
  DashboardStatsResponse,
  DeleteTemplatePayload,
  EditBotStation,
  EditScrapTask,
  GetMailMessagePayload,
  GetMailMessageResponse,
  GetTemplatesPayload,
  Mail,
  PauseBotStation,
  ScrapTask,
  UpdateTemplatesPayload,
  ViewBotsLogsPayload,
  ViewBotsLogsResponse,
  WorkloadStatsResponse,
} from './types'

export const dashboardApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBotsStations: build.query<TableServerResponse<BotStation[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/bot-dashboard/bot-stations/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'BotsStations', id: 'BOTS_STATIONS' }],
    }),
    editBotStation: build.mutation<{ msg: string }, EditBotStation>({
      query: (body) => ({
        url: '/api/bot-dashboard/bot-stations',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'BotsStations', id: 'BOTS_STATIONS' }],
    }),

    pauseBotStation: build.mutation<{ msg: string }, PauseBotStation>({
      query: (body) => ({
        url: '/api/bot-dashboard/bot-stations/pause',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'BotsStations', id: 'BOTS_STATIONS' }],
    }),
    getBotsLogs: build.query<TableServerResponse<BotLog[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/bot-dashboard/logs/paging',
        method: 'POST',
        data: body,
      }),
      transformResponse: (data: any) => ({
        ...data,
        data: data.data.map((item: any, ind: number) => ({ ...item, id: ind })),
      }),
      providesTags: () => [{ type: 'DashboardLogs', id: 'DASHBOARD_LOGS' }],
    }),
    viewBotsLogs: build.query<ViewBotsLogsResponse, ViewBotsLogsPayload>({
      query: (body) => ({
        url: '/api/bots/logs',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'DashboardLogs', id: 'DASHBOARD_LOGS' }],
    }),
    getMails: build.query<TableServerResponse<Mail[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/mails/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'DashboardMails', id: 'DASHBOARD_MAILS' }],
    }),
    getMailMessage: build.query<GetMailMessageResponse, GetMailMessagePayload>({
      query: ({ catcherId, mailId }) => ({
        url: `/api/mails?catcher_id=${catcherId}&mail_id=${mailId}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'DashboardMails', id: 'DASHBOARD_MAILS' }],
    }),
    getMailFile: build.mutation<Blob, { filename: string }>({
      query: ({ filename }) => ({
        url: `/api/mails/files/${filename}`,
        method: 'GET',
        responseType: 'blob',
      }),
      invalidatesTags: [{ type: 'DashboardMails', id: 'DASHBOARD_MAILS' }],
    }),
    getScrapTasks: build.query<TableServerResponse<ScrapTask[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/mails/scrap_tasks/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'ScrapTasks', id: 'SCRAP_TASKS' }],
    }),
    enableTasks: build.mutation<{ msg: string }, { task_names: string[] }>({
      query: (body) => ({
        url: '/api/mails/scrap_tasks',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'ScrapTasks', id: 'SCRAP_TASKS' }],
    }),
    editScrapTasks: build.mutation<{ msg: string }, EditScrapTask>({
      query: (body) => ({
        url: '/api/mails/scrap_tasks',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'ScrapTasks', id: 'SCRAP_TASKS' }],
    }),
    createReport: build.mutation<any, CreateReportPayload>({
      query: (body) => ({
        url: '/api/bot-dashboard/reports/generate',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'DashboardReport', id: 'DASHBOARD_REPORT' }],
    }),
    downloadReport: build.mutation<Blob, { fileName: string }>({
      query: ({ fileName }) => ({
        url: `/api/bot-dashboard/reports/download?file_name=${fileName}`,
        method: 'GET',
        responseType: 'blob',
      }),
      invalidatesTags: () => [{ type: 'DashboardReport', id: 'DASHBOARD_REPORT' }],
    }),
    getSqlTemplates: build.query<GetTemplatesPayload, void>({
      query: (body) => ({
        url: '/api/bot-dashboard/reports',
        method: 'GET',
        data: body,
      }),
      providesTags: () => [{ type: 'ReportTemplates', id: 'REPORT_TEMPLATES' }],
    }),
    updateSqlTemplates: build.mutation<{ msg: string }, UpdateTemplatesPayload>({
      query: (body) => ({
        url: '/api/bot-dashboard/reports/edit',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'ReportTemplates', id: 'REPORT_TEMPLATES' }],
    }),
    deleteSqlTemplates: build.mutation<{ msg: string }, DeleteTemplatePayload>({
      query: (body) => ({
        url: '/api/bot-dashboard/reports/delete',
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'ReportTemplates', id: 'REPORT_TEMPLATES' }],
    }),
    getDashboardStats: build.query<DashboardStatsResponse, string | undefined>({
      query: (ticket_system_id) => ({
        url: '/api/bot-dashboard/dashboard-stats',
        method: 'GET',
        ...(ticket_system_id && { params: { ticket_system_id } }),
      }),
    }),
    getWorkloadStats: build.query<WorkloadStatsResponse, void>({
      query: (body) => ({
        url: '/api/bot-dashboard/workload',
        method: 'GET',
        data: body,
      }),
    }),
  }),
})

export const {
  useGetBotsStationsQuery,
  useEditBotStationMutation,
  usePauseBotStationMutation,
  useGetBotsLogsQuery,
  useGetMailFileMutation,
  useGetMailMessageQuery,
  useLazyGetMailsQuery,
  useGetScrapTasksQuery,
  useEnableTasksMutation,
  useEditScrapTasksMutation,
  useCreateReportMutation,
  useGetSqlTemplatesQuery,
  useUpdateSqlTemplatesMutation,
  useDeleteSqlTemplatesMutation,
  useDownloadReportMutation,
  useGetDashboardStatsQuery,
  useLazyViewBotsLogsQuery,
  useGetWorkloadStatsQuery,
} = dashboardApi
